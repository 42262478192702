import gql from 'graphql-tag';

export const queryForAlarmLimits = gql`
query queryForAlarmLimits($limit: Int, $page: Int, $query: AlarmLimitSortOrQuery, $sort: AlarmLimitSortOrQuery) {
    queryForAlarmLimits(limit: $limit, page: $page, query: $query, sort: $sort) {
        items {
            id
            facilityId
            Facility {
                facilityName
                facilityUid
                id
            }
            assetIdentifier
            alarmAttributeName
            value
            effectiveDateTime
            updater
            updatedAt
            updateReason
            datatype
        }
        totalRecords
    }
}`;

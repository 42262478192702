import { Injectable } from '@angular/core';
import { BaseTableService } from './base-table.service';
import { SelectItem } from 'primeng/api';
import { OpAlertLog } from '../model/opAlertLogs/op-alert-log';

@Injectable({
  providedIn: 'root'
})
export class OpAlertLogTableService extends BaseTableService<OpAlertLog> {
  private lastScreen: string;
  private statusOptions: SelectItem[];
  private addFilterOoptions: any;

  constructor() {
    super();
    this.setStatusOptions();
   }

  setLastScreen(screen: string) {
    this.lastScreen = screen;
  }

  getLastScreen() {
    return this.lastScreen;
  }

  setStatusOptions(statusOptionsLs: SelectItem[] = null) {
    if (statusOptionsLs == null) {
    this.statusOptions = [
      { label: 'Choose', value: null },
      { label: 'New', value: 'N' },
      { label: 'Acknowledged', value: 'A' }];
    } else {
      this.statusOptions = statusOptionsLs;
    }
  }

  getStatusOptions () {
    return this.statusOptions;
  }

  getAdditionalFilterOptions(): any {
    return this.addFilterOoptions;
  }

  setAdditionalFilterOptions(options: any): void {
    this.addFilterOoptions = options;
  }
}

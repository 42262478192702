import gql from 'graphql-tag';

export const getStationLog = gql`
  query getStationLog($logId: String) {
    getStationLog(logId: $logId) {
      id
      date
      reasonId
      message
      fixSolution
      unitHours
      createdAt
      createdBy
      deletedAt
      updatedAt
      updater
      Reason {
        id
        reason
      }
      Facility {
        id
        facilityName
        facilityUid
        flocNumber
        Aor {
          id
          name
        }
      }
    }
  }
`;

import { SelectItem } from 'primeng/api';
import { Column } from '../../model/common/column';

export function getStationLogColumns(availableReasons: SelectItem[]): Column[] {
  return [
    {
      field: 'date',
      header: 'OPMONITORING.LABEL.DATE',
      formatPattern: 'text',
      width: '5%',
      filterable: true,
      sortable: true,
      matchMode: 'contains'
    },
    {
      field: 'facility',
      subField: 'facilityName',
      header: 'OPMONITORING.LABEL.STATION',
      mobileHeader: 'OPMONITORING.LABEL.STATION',
      formatPattern: 'text',
      width: '10%',
      sortable: true,
      filterable: true
    },
    {
      field: 'reason',
      subField: 'reason',
      header: 'OPMONITORING.LABEL.REASON',
      mobileHeader: 'OPMONITORING.LABEL.REASON',
      formatPattern: 'text',
      width: '5%',
      sortable: true,
      options: availableReasons,
      filterable: true
    },
    {
      field: 'message',
      header: 'OPMONITORING.LABEL.MESSAGE',
      mobileHeader: 'OPMONITORING.LABEL.MESSAGE',
      formatPattern: 'text',
      width: '30%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    },
    {
      field: 'fixSolution',
      header: 'OPMONITORING.LABEL.SOLUTION',
      mobileHeader: 'OPMONITORING.LABEL.SOLUTION',
      formatPattern: 'text',
      width: '30%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    },
    {
      field: 'unitHours',
      header: 'OPMONITORING.LABEL.HOURS',
      mobileHeader: 'OPMONITORING.LABEL.HOURS',
      formatPattern: 'text',
      width: '5%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    }
  ];
}

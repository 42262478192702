import { gql } from 'graphql-tag'

export const editAlarmLimit = gql`
mutation editAlarmLimit($id: String, $input: AlarmLimitInput){
    editAlarmLimit(id: $id, input: $input){
        id
        facilityId
        assetIdentifier
        alarmAttributeName
        value
        effectiveDateTime
        updatedAt
        updateReason
        datatype
    }
}`

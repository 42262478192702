import gql from 'graphql-tag';

export const createOpAlertLog = gql`
mutation createOpAlertLog($input: OpAlertLogInput) {
  createOpAlertLog(input: $input) {
    opLogId
    opLogStatus
    facilityId
    state
    county
    township
    businessUnitId
    createdBy
    createdAt
    updatedBy
    updatedAt
  }
}`;

<p-dialog [modal]="true" [(visible)]="isVisible" (onHide)="onDialogHide()" [dismissableMask]="true"
    header="View Audit Info">
    <div class="container">
        <ul *ngIf="!isLoading">
            <li *ngFor="let history of histories; index as i">
                User <strong>{{ history.updater }}</strong> changed Value to <strong>{{history.value}}</strong>,
                Effective at <strong>{{ history.effectiveDateTime | date: "MM/dd/yyyy HH:mm:ss" }}</strong>, 
                Reason for Update: <strong>{{ history.updateReason }}</strong>.
            </li>
        </ul>
        <p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
    </div>
</p-dialog>
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { take } from 'rxjs/operators';
import { BusinessUnit } from 'src/app/model/admin/business-unit';
import { Facility } from 'src/app/model/locations/facility';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { OperationalMonitoringApiService } from 'src/app/services/operational-monitoring-api.service';
import { sortByProperty } from 'src/app/utils/utils';

@Component({
  selector: 'app-request-alarm-monitoring',
  templateUrl: './request-alarm-monitoring.component.html',
  styleUrls: ['./request-alarm-monitoring.component.scss'],
})
export class RequestAlarmMonitoringComponent {
  @Input() isVisible: boolean = false;

  @Output() requestIsReady: EventEmitter<any> = new EventEmitter();
  @Output() hideRequest: EventEmitter<any> = new EventEmitter();

  canEditAll: boolean = false;
  canEditOwn: boolean = false;
  canEditDirectReports: boolean = false;
  loading: boolean = true;
  sending: boolean = false;

  businessUnits: BusinessUnit[] = [];
  facilities: Facility[] = [];
  totalFacilities: Facility[] = [];
  facilityUIds: SelectItem[] = [];

  alarmMonitoring = new UntypedFormGroup({
    businessUnit: new UntypedFormControl('', Validators.required),
    facilityUId: new UntypedFormControl('', Validators.required),
    monitoredItem: new UntypedFormControl('', Validators.required),
    additionalInfo: new UntypedFormControl(''),
  });

  constructor(
    private _authApi: AuthApiService,
    protected _auth: AuthService,
    private _facilityApi: FacilityApiService,
    protected _opMonitoringApi: OperationalMonitoringApiService,
    protected _logAndMessage: LogAndMessageService,
    private _cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.canEditAll = this._authApi.doesUserHaveAllClaimsFromList([
      'ChangeAllAlarmLimits',
    ]);
    this.canEditOwn = this._authApi.doesUserHaveAtLeastOneClaimFromList([
      'ChangeOwnAlarmLimits',
    ]);
    this.canEditDirectReports =
      this._authApi.doesUserHaveAtLeastOneClaimFromList([
        'ChangeDirectReportsAlarmLimits',
      ]);
    this.prepareRequestForm();
  }

  onDialogHide($event: any) {
    this.hideRequest.emit();

    this.facilities = this.totalFacilities;
    this.getFacilityUIds();

    setTimeout(() => {
      this.alarmMonitoring.enable();
      this.alarmMonitoring.markAsPristine();
      this.alarmMonitoring.patchValue({
        businessUnit: null,
        facilityUId: null,
        monitoredItem: null,
        additionalInfo: null,
      });
    }, 50);
  }

  ngOnDestroy(): void {}

  prepareRequestForm() {
    this.loadBusinessUnits();
    this.loadFacilityUIds();
  }

  loadBusinessUnits() {
    this.businessUnits = this._authApi.getUserBusinessUnits();
  }

  loadFacilityUIds() {
    if (this.canEditAll) {
      this._facilityApi
        .getFacilities()
        .pipe(take(1))
        .subscribe(
          (data) => {
            if (data.length === 0) {
              setTimeout(() => {
                this.loadFacilityUIds();
              }, 1000);
              return;
            }
            this.facilities = data;
            this.sortFacilitiesDropdown();
            this.getFacilityUIds();
          },
          (error) => console.error(error)
        );
    } else if (this.canEditDirectReports || this.canEditOwn) {
      this._facilityApi
        .getUserFacilities(this._auth.getEmail(), this.canEditDirectReports)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            this.facilities = data.getUserFacilities;
            this.sortFacilitiesDropdown();
            this.getFacilityUIds();
          },
          (error) => console.error(error)
        );
    } else {
      this.requestIsReady.emit();
    }
  }

  sortFacilitiesDropdown() {
    this.facilities.sort((a, b) => sortByProperty(a, b, 'facilityUid'));
    this.totalFacilities = this.facilities;
    this.loading = false;
    this.requestIsReady.emit();
  }

  getFacilityUIds() {
    this.facilityUIds = [];
    this.facilities.forEach((fac) => {
      this.facilityUIds.push({
        label: fac.facilityUid,
        value: fac.facilityUid,
      });
    });
  }

  onBusinessUnitChanged($event) {
    this.alarmMonitoring.patchValue({
      businessUnit: $event.value,
    });

    this.filterFacilities();
  }

  filterFacilities() {
    this.facilities = this.totalFacilities.filter((fac) => {
      return (
        fac.businessUnit.id ===
        this.alarmMonitoring.get('businessUnit').value.id
      );
    });

    this.getFacilityUIds();
  }

  onFacilityUIdChanged($event) {
    this.alarmMonitoring.patchValue({
      facilityUId: $event.value,
    });
  }

  onSubmit() {
    this.sending = true;
    let submittedRequest = this.alarmMonitoring.value;

    let alarmLimitRequest = {
      businessUnit: submittedRequest.businessUnit.name,
      facilityUid: submittedRequest.facilityUId,
      monitoredItem: submittedRequest.monitoredItem,
      additionalInfo: submittedRequest.additionalInfo,
    };

    this._opMonitoringApi
      .sendAlarmLimitRequestEmail(alarmLimitRequest)
      .pipe(take(1))
      .subscribe(
        ({ result }) => {
          this.isVisible = false;
        },
        (error) => {
          console.error(error);
          this.sending = false;
          this._logAndMessage.translateToErrorMessage({
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
            bodyKey: 'OPMONITORING.MESSAGES.ERROR.EMAIL_ERROR',
          });
        },
        () => {
          this.sending = false;
          this._logAndMessage.translateToSuccessMessage({
            headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
            bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.EMAIL_REQUEST',
          });
          this._cdRef.detectChanges();
        }
      );
  }
}

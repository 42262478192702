@if (loading()) {
<app-progress-spinner [enabled]="loading()"></app-progress-spinner>
} @else { PI Value at time {{ piValue().timestamp | date : "short" }}:
<span id="value" (click)="openChartDialog()">{{
  piValue().value | number : "1.2-3"
}}</span>
}

<p-dialog
  header="Notification PI Data - Last {{ hours() }} hours"
  [(visible)]="showChartDialog"
  showEffect="fade"
  [modal]="true"
  [transitionOptions]="'0ms'"
  (onHide)="onChartHide()"
  [style]="{ width: '40vw' }"
>
  @if (plotLoading()) {
  <app-progress-spinner [enabled]="plotLoading()"></app-progress-spinner>
  } @else {
  <p-chart type="line" [data]="chartData" [options]="chartOptions"></p-chart>
  }
</p-dialog>

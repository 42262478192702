import gql from 'graphql-tag';

export const getAlarmLimits = gql`
query getAlarmLimits {
    getAlarmLimits {
        id
        Facility {
            id
            facilityName
            facilityUid
        }
        assetIdentifier
        alarmAttributeName
        value
        effectiveDateTime
        updatedAt
        updateReason
        datatype
    }
}`;

<div id="alarm-limits-search-container">
  <p-blockUI [blocked]="loading || formLoading || requestLoading"></p-blockUI>

  <app-progress-spinner
    [enabled]="!elements || formLoading || requestLoading"
  ></app-progress-spinner>

  <div class="content">
    <div class="button-wrapper content-header">
      <div id="business-units-wrapper">
        <app-business-unit-filter
          (selectedBusinessUnitsChanged)="handleBusinessUnitChange($event)"
          #buFilter
        ></app-business-unit-filter>
      </div>
      <button
        pButton
        class="width-eighty"
        label="{{ 'OPMONITORING.LABEL.BUTTONS.SYNC' | translate }}"
        icon="fa fa-fw fa-clone"
        (click)="syncAlarmLimits()"
        *ngIf="canSync"
      ></button>
      <button
        pButton
        class="width-eighty"
        label="{{ 'OPMONITORING.LABEL.BUTTONS.REQUEST' | translate }}"
        icon="fa fa-fw fa-envelope"
        (click)="requestAlarmMonitoring()"
        *ngIf="canCreate"
      ></button>
      <!--
        We don't want users to hit this button and get confused since it doesn't make a limit in PI
        but we are keeping it here just hidden in case in the future they decide they want it back
      -->
      @if (false) {
      <button
        pButton
        class="width-eighty"
        label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
        icon="fa fa-fw fa-plus-circle"
        (click)="newAlarmLimit()"
        *ngIf="canCreate"
      ></button>
      }
      <button
        pButton
        class="width-eighty"
        label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
        icon="fa fa-fw fa-eraser"
        (click)="clearScreen()"
      ></button>
      <button
        pButton
        class="refresh-button width-twenty"
        icon="fa fa-fw fa-refresh"
        (click)="refresh()"
      ></button>
    </div>

    <div *ngIf="ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])">
      <app-general-grid
        [values]="elements"
        [columns]="columns"
        [loading]="loading"
        [deviceSize]="getDeviceSize()"
        [totalRecords]="totalRecords"
        [filterable]="true"
        [screenName]="screenName"
        [rows]="rows"
        [lazy]="true"
        (lazyLoad)="lazyLoad($event)"
        [includeExportCSV]="false"
        (changeViewEvent)="changeViewEvent($event)"
        scrollHeight="calc(100vh - 325px)"
        [usePaginator]="true"
        [alwaysShowPaginator]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
        #grid
      >
      </app-general-grid>
    </div>
  </div>

  <app-alarm-limits-detail-container
    [isVisible]="displayDialog"
    [formType]="formType"
    [selectedAlarmLimit]="selectedAlarmLimit"
    (hideForm)="onFormHidden()"
    (formSubmitted)="onFormSubmitted()"
    (alarmLimitDeleted)="onAlarmDeleted()"
    (formIsReady)="onPreparedForm()"
    #alarmLimitForm
  ></app-alarm-limits-detail-container>

  <app-request-alarm-monitoring
    [isVisible]="displayRequestDialog"
    (hideRequest)="onRequestHidden()"
    (requestIsReady)="onPreparedRequest()"
    #requestAM
  ></app-request-alarm-monitoring>
</div>

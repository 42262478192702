import gql from 'graphql-tag';

export const editOpAlertLog = gql`
mutation editOpAlertLog($input: OpAlertLogInput) {
  editOpAlertLog(input: $input) {
    id
    opLogStatus
    facilityId
    state
    county
    township
    businessUnitId
    createdBy
    createdAt
    updatedBy
  }
}`;

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { forkJoin, interval, Subscription, take } from 'rxjs';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { getStationLogColumns } from '../../definitions/station-logs-table-definition';
import { StationLog } from 'src/app/model/station-logs/station-log';
import { StationLogApiService } from 'src/app/services/station-log-api.service';
import { StationLogBuilderService } from 'src/app/services/station-log-builder.service';
import { StationLogTableService } from 'src/app/services/station-log-table.service';
import { TabService } from 'src/app/core/services/tab.service';
import { StationLogReason } from 'src/app/model/station-logs/station-log-reason';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';

@Component({
  selector: 'app-station-logs-search-container',
  templateUrl: './station-logs-search-container.component.html',
  styleUrls: ['./station-logs-search-container.component.scss'],
})
export class StationLogsSearchContainerComponent
  extends SearchContainerComponent<StationLog>
  implements OnInit, OnDestroy, AfterViewInit
{
  allLogs: StationLog[] = [];
  loading: boolean;
  private _isRefreshing = false;
  refreshSubscription: Subscription;
  availableReasons: SelectItem[];

  constructor(
    private _translateService: TranslateService,
    protected _deviceService: DeviceService,
    private _breadCrumbBuilderService: BreadCrumbBuilderService,
    private _stationLogApi: StationLogApiService,
    private _stationLogBuilder: StationLogBuilderService,
    private _authApi: AuthApiService,
    protected _cdRef: ChangeDetectorRef,
    protected _loader: LoadingService,
    protected _tableService: StationLogTableService,
    protected _auth: AuthService,
    protected _logAndMessage: LogAndMessageService
  ) {
    super(_deviceService, _loader, _auth, _cdRef);
  }

  ngOnInit() {
    this.setTableService(this._tableService);
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }

    const refreshInterval = interval(60000);
    this.refreshSubscription = refreshInterval.subscribe(() => {
      this.refresh(false);
    });
  }

  initialize() {
    this._stationLogApi
    .getStationLogReasons()
    .pipe(take(1))
    .subscribe(
      (data: StationLogReason[]) => {
        this.availableReasons = [
          { label: 'Choose', value: null },
          ...data
            .map((d) => ({ label: d.reason, value: d.id })),
        ];

        this.columns = getStationLogColumns(this.availableReasons);
      },
      (error) => {
        this._logAndMessage.errorLogOnly(error);
        this._logAndMessage.translateToErrorMessage({
          bodyKey: 'OPMONITORING.MESSAGES.ERROR.STATION_LOG_LOAD',
          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        });

        this.loading = false;
        this._isRefreshing = false;
      }
    );

    const breadCrumb$ = this._translateService.get(
      'OPMONITORING.SCREEN.STATION_LOGS'
    );
    const header$ = this._translateService.get(
      'OPMONITORING.SCREEN.STATION_LOGS'
    );
    forkJoin([header$, breadCrumb$])
      .pipe(take(1))
      .subscribe((mes) => {
        if (this._deviceService.isMobile()) {
          this._breadCrumbBuilderService.resetAndAddBreadCrumb({
            label: mes[1],
            routerLink: null,
          });
        } else {
          TabService.getInstance().updateActiveTabLabel(mes[0]);
        }
        this.screenName = mes[0];
      });
  }

  refresh(setLoading = true): void {
    this.lazyLoad({ lazy: this._tableService.getLastLazyLoad() }, setLoading);
  }

  handleLazyLoad(req, $event, filters) {
    const query = this._stationLogBuilder.buildStationLogQuery(filters);
    this.queryNetwork(req, $event, query);
  }

  private queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      let field = $event.lazy.sortField;

      sort[field] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
      this._tableService.setLastSortField($event.lazy.sortField);
      this._tableService.setLastSortDirection(
        $event.lazy._tableServicesortOrder
      );
    } else {
      sort['createdAt'] = 'DESC';
      this._tableService.setLastSortField('createdAt');
      this._tableService.setLastSortDirection(0);
    }

    if (!this._isRefreshing) {
      this._isRefreshing = true;
      this._stationLogApi
        .queryForStationLogs(req.pageSize, req.page, query, sort)
        .pipe(take(1))
        .subscribe(
          (data: StationLog[]) => {
            this.totalRecords = data.length;
            this.allLogs = data;
            this.elements = [...data];
            this.loading = false;
            this._cdRef.markForCheck();
            this._isRefreshing = false;
          },
          (error) => {
            this._logAndMessage.errorLogOnly(error);
            this._logAndMessage.translateToErrorMessage({
              bodyKey: 'OPMONITORING.MESSAGES.ERROR.STATION_LOG_LOAD',
              headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
            });

            this.loading = false;
            this._isRefreshing = false;
          }
        );
    }
  }

  clearScreen() {
    this.lazyLoad({ lazy: {} });
    this.grid.resetTable();
    this._tableService.clearResults();
    this.elements = [];
  }

  changeViewEvent($event) {
    this._tableService.setSelected($event.data);
    this._tableService.setLastScreen('all');

    // TODO - Open Dialog
  }

  ngAfterViewInit(): void {
    this.applyPreviousFilters();
    this.applyPreviousData();
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
  }
}

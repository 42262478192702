import gql from 'graphql-tag';

export const getStationLogReasons = gql`
  query getStationLogReasons {
    getStationLogReasons {
      id
      reason
    }
  }
`;

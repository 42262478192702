import { Injectable } from '@angular/core';
import { BaseTableService } from './base-table.service';
import { SelectItem } from 'primeng/api';
import { StationLog } from '../model/station-logs/station-log';

@Injectable({
  providedIn: 'root'
})
export class StationLogTableService extends BaseTableService<StationLog> {
  private lastScreen: string;

  constructor() {
    super();
   }

  setLastScreen(screen: string) {
    this.lastScreen = screen;
  }

  getLastScreen() {
    return this.lastScreen;
  }
}

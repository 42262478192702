import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OpAlertLogAlarm } from 'src/app/model/opAlertLogs/op-alert-log-alarm';
import { OpAlertLogEntry } from 'src/app/model/opAlertLogs/op-alert-log-entry';

@Component({
  selector: 'app-op-logs-entry',
  templateUrl: './op-logs-entry.component.html',
  styleUrls: ['./op-logs-entry.component.scss'],
})
export class OpLogsEntryComponent implements OnInit {
  @Input() entry: OpAlertLogAlarm | OpAlertLogEntry;
  @Input() writeAccess = false;
  @Output() editEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  innerHTML() {
    if ('entry' in this.entry) {
      return this.entry.entry;
    } else if ('alarm' in this.entry) {
      return this.entry.alarm;
    }
  }
}

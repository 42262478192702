import { gql } from 'graphql-tag'

export const addAlarmLimit = gql`
mutation addAlarmLimit($input: AlarmLimitInput){
    addAlarmLimit(input: $input){
        id
        facilityId
        assetIdentifier
        alarmAttributeName
        value
        effectiveDateTime
        updatedAt
        updateReason
        datatype
    }
}`

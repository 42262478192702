import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { CanDeactivateGuard } from '../guards/deactivate.guard';
import { OplogsDetailContainerComponent } from './containers/oplogs-detail-container/oplogs-detail-container.component';
import { OplogsAllLogsSearchContainerComponent } from './containers/oplogs-all-logs-search-container/oplogs-all-logs-search-container.component';
import { OpAlertLogPrintContainerComponent } from './containers/op-alert-log-print-container/op-alert-log-print-container.component';
import { StationLogsSearchContainerComponent } from './containers/station-logs/station-logs-search-container.component';

const routes: Routes = [
  { path: 'logs/details/:id', component: OplogsDetailContainerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'logs/details', component: OplogsDetailContainerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'logs/all', component: OplogsAllLogsSearchContainerComponent, canActivate: [AuthGuard] },
  { path: 'logs/print/:id', component: OpAlertLogPrintContainerComponent, canActivate: [AuthGuard]},
  { path: 'operational-monitoring/station-logs', component: StationLogsSearchContainerComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OplogsRoutingModule { }

import { gql } from '@apollo/client/core';

export const editStationLog = gql`
  mutation editStationLog($input: StationLogInput) {
    editStationLog(input: $input) {
      id
      date
      reasonId
      message
      fixSolution
      unitHours
      createdAt
      createdBy
      deletedAt
      updatedAt
      updater
      Reason {
        id
        reason
      }
    }
  }
`;

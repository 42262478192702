import gql from 'graphql-tag';

export const createOpAlertLogNotification = gql`
mutation createOpAlertLogNotification($input: OpAlertLogAlarmNotification) {
  createOpAlertLogNotification(input: $input) {
    opAlertLogId
    time
    user
    note
  }
}`;

import gql from 'graphql-tag';

export const getLatestPIValue = gql`
  query getLatestPIValue($piPoint: String!) {
    getLatestPIValue(piPoint: $piPoint) {
      timestamp
      unitsAbbreviation
      value
      good
      questionable
      substituted
      annotated
    }
  }
`;

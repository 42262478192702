import gql from 'graphql-tag';

export const getAlarmLimitHistories = gql`
query getAlarmLimitHistories($parentId: String) {
  getAlarmLimitHistories(parentId: $parentId) {
    id
    timestamp
    value
    effectiveDateTime
    updater
    updateReason
  }
}`;

import gql from 'graphql-tag';

export const createOpAlertLogAlarm = gql`
mutation createOpAlertLogAlarm($input: OpAlertLogAlarmInput) {
  createOpAlertLogAlarm(input: $input) {
    opAlertLogId
    time
    user
    alarm
    createdBy
    createdAt
    updatedAt
  }
}`;

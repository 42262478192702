import { AfterContentChecked, Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { take } from 'rxjs/operators';
import { PRINT_TAB_PREFIX } from 'src/app/constants/common.constants';
import { DetailsContainer } from 'src/app/core/containers/details-container';
import { TabService } from 'src/app/core/services/tab.service';
import { AuthService } from 'src/app/services/auth.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { DeviceService } from 'src/app/services/device.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityBuilderService } from 'src/app/services/facility-builder.service';
import { FacilityTableService } from 'src/app/services/facility-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { isNullOrUndefined } from 'util';

import { OpAlertLogTableService } from 'src/app/services/op-alert-log-table.service';
import { OperationalMonitoringApiService } from 'src/app/services/operational-monitoring-api.service';
import { OpAlertLogsBuilderService } from 'src/app/services/op-alert-logs-builder.service';
import { OpAlertLog } from 'src/app/model/opAlertLogs/op-alert-log';

@Component({
  selector: 'app-op-alert-log-print-container',
  templateUrl: './op-alert-log-print-container.component.html',
  styleUrls: ['./op-alert-log-print-container.component.scss']
})
export class OpAlertLogPrintContainerComponent extends DetailsContainer implements OnInit, OnDestroy, AfterContentChecked {

  opAlertLog: OpAlertLog;

  constructor(protected _translateService: TranslateService,
    protected _deviceService: DeviceService,
    protected _logAndMessage: LogAndMessageService,
    protected _confirmationService: ConfirmationService,
    private _opMonitoringApi: OperationalMonitoringApiService,
    private _opAlertLogBuilder: OpAlertLogsBuilderService,
    protected _locationApi: LocationApiService,
    protected _contactApi: ContactApiService,
    protected _facilityApi: FacilityApiService,
    protected _facilityBuilder: FacilityBuilderService,
    protected _facilityTableService: FacilityTableService,
    private _opAlertLogTableService: OpAlertLogTableService,
    private _loader: LoadingService) {
    super(_translateService, _deviceService, _confirmationService,
      _locationApi, _contactApi, _facilityTableService, _facilityApi);
    }

  ngOnInit() {
    this.setupTabClosingSubscription();
    if (this._loader.isLoaded()) {
      this.initializeMonLog();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initializeMonLog();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.tabClosingSub) {
      this.tabClosingSub.unsubscribe();
    }
  }

  ngAfterContentChecked(): void {
    const activeId = this.id;
    if (!isNullOrUndefined(activeId) && !isNullOrUndefined(this.opAlertLog) && this.opAlertLog.id !== activeId
      && !this.doCheckComplete) {
      this.doCheckComplete = true;
      this._opAlertLogTableService.setSelected(undefined);
      this.initializeMonLog();
    }
  }

  initializeMonLog() {
    const id = this.id;

    if (!id) {
      this.opAlertLog = this._opAlertLogTableService.getSelected();
      // no id and nothing cached, go back to search.
      if (!this.opAlertLog) {
        this._logAndMessage.errorLogOnly('Mon log was null');
        const index = TabService.getInstance().getActiveIndex();
        TabService.getInstance().closeTab(index);
      }
      this.initialize();
    } else {
      this._opMonitoringApi.getOpAlertLog(id).pipe(take(1)).subscribe(({ data }) => {
        const clone = Object.assign({}, data);
        if (clone && clone.getOpAlertLog) {
          this.opAlertLog = this._opAlertLogBuilder.buildOpAlertLog(clone.getOpAlertLog);
          this.doCheckComplete = false;
          this.initialize();
        } else {
          // nothing found, go back to search.
          this._logAndMessage.errorLogOnly('Could not find op alert log with ID: ' + id);
          const index = TabService.getInstance().getActiveIndex();
          TabService.getInstance().closeTab(index);
        }
      });
    }
  }

  initialize() {
    TabService.getInstance().updateActiveTabLabel(PRINT_TAB_PREFIX + this.opAlertLog.opLogId + ' (Operator Alert)');
  }

  print() {
    // This used to use printjs, but we ran into some issues with lines
    // overlapping each other in the migration to angular 13, so now
    // we use @media print styling to only show what is needed
    // and use the default window.print() to generate the document!
    window.print();
  }
}

import { Column } from '../../model/common/column';
import { DeviceSize } from 'src/app/core/enums/deviceSize.enum';
import { SelectItem } from 'primeng/api';

export function getOpLogColumns(deviceSize, statusNames: SelectItem[], isMobile: boolean): Column[] {
  if ((deviceSize === DeviceSize.MD || deviceSize === DeviceSize.LG || deviceSize === DeviceSize.XL) && !isMobile) {
    return getOpLogColumnsLg(statusNames);
  } else {
    return getOpLogColumnsSm(statusNames);
  }
}

function getOpLogColumnsLg(statusNames: SelectItem[]): Column[] {
  return [
    {
      field: 'indicators',
      header: 'COMMON.LABEL.EMPTY_COL_HEADER',
      formatPattern: 'icons',
      type: 'icons',
      width: '3%',
      filterable: false,
    },
    {
      field: 'opLogId',
      header: 'OPMONITORING.LABEL.LOG_ID',
      formatPattern: 'text',
      width: '6%',
      filterable: true,
      sortable: true,
      matchMode: 'contains'
    },
    {
      field: 'facilityName',
      header: 'LOCATION.LABEL.FACILITY_NAME',
      mobileHeader: 'LOCATION.LABEL.FACILITY_NAME',
      formatPattern: 'text',
      width: '10%',
      sortable: true,
      filterable: true
    },
    {
      field: 'facilityUid',
      header: 'LOCATION.LABEL.FACILITY_UID',
      mobileHeader: 'LOCATION.LABEL.FACILITY_UID',
      formatPattern: 'text',
      width: '5%',
      sortable: true,
      filterable: true
    },
    {
      field: 'facilityTypeName',
      header: 'LOCATION.LABEL.FACILITY_TYPE',
      mobileHeader: 'LOCATION.LABEL.FACILITY_TYPE',
      formatPattern: 'text',
      width: '10%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    },
    //##############################################
    {
      field: 'state',
      header: 'LOCATION.LABEL.STATE',
      mobileHeader: 'LOCATION.LABEL.STATE',
      formatPattern: 'text',
      width: '5%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    },
    {
      field: 'county',
      header: 'LOCATION.LABEL.COUNTY',
      mobileHeader: 'LOCATION.LABEL.COUNTY',
      formatPattern: 'text',
      width: '5%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    },
    {
      field: 'township',
      header: 'LOCATION.LABEL.TOWNSHIP',
      mobileHeader: 'LOCATION.LABEL.TOWNSHIP',
      formatPattern: 'text',
      width: '5%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    },
    //##############################################
    {
      field: 'opLogStatus',
      header: 'OPMONITORING.LABEL.STATUS',
      mobileHeader: 'OPMONITORING.LABEL.STATUS',
      formatPattern: 'text',
      type: 'dropdown',
      width: '5%',
      sortable: true,
      filterable: true,
      options: statusNames
    },
    {
      field: 'createdBy',
      header: 'OPMONITORING.LABEL.CREATED_BY',
      mobileHeader: 'OPMONITORING.LABEL.CREATED_BY',
      formatPattern: 'text',
      width: '15%',
      sortable: true,
      filterable: true,
    },
    {
      field: 'createdAt',
      header: 'OPMONITORING.LABEL.CREATED_AT',
      mobileHeader: 'OPMONITORING.LABEL.CREATED_AT',
      formatPattern: 'calendar',
      type: 'calendar',
      width: '10%',
      sortable: true,
      filterable: true,
      dateFormat: 'yyyy-MM-dd hh:mm aaa'
    }
  ];
}


function getOpLogColumnsSm(statusNamesSm: SelectItem[]): Column[] {
  return [
    {
      field: 'opLogId',
      header: 'OPMONITORING.LABEL.LOG_ID',
      mobileHeader: 'OPMONITORING.LABEL.LOG_ID',
      formatPattern: 'hyperlink',
      type: 'text',
      width: '10%',
      filterable: false,
    },
    {
      field: 'facilityName',
      header: 'LOCATION.LABEL.FACILITY_NAME',
      mobileHeader: 'LOCATION.LABEL.FACILITY_NAME',
      formatPattern: 'text',
      width: '20%',
      sortable: true,
      filterable: true
    },
    {
      field: 'facilityUid',
      header: 'LOCATION.LABEL.FACILITY_UID',
      mobileHeader: 'LOCATION.LABEL.FACILITY_UID',
      formatPattern: 'text',
      width: '20%',
      sortable: true,
      filterable: true
    },
    {
      field: 'opLogStatus',
      header: 'OPMONITORING.LABEL.STATUS',
      mobileHeader: 'OPMONITORING.LABEL.STATUS',
      formatPattern: 'text',
      type: 'dropdown',
      width: '10%',
      sortable: true,
      filterable: true,
      options: statusNamesSm
    },
    {
      field: 'createdAt',
      header: 'MONLOG.LABEL.CREATED_AT',
      mobileHeader: 'MONLOG.LABEL.CREATED_AT',
      formatPattern: 'calendar',
      type: 'calendar',
      width: '20%',
      sortable: true,
      filterable: true,
      dateFormat: 'yyyy-MM-dd hh:mm aaa'
    }
  ];
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { AlarmLimit } from 'src/app/model/operational-monitoring/alarm-limit';
import { AlarmLimitHistory } from 'src/app/model/operational-monitoring/alarm-limit-history';
import { OperationalMonitoringApiService } from 'src/app/services/operational-monitoring-api.service';

@Component({
  selector: 'app-alarm-limit-audit-trail',
  templateUrl: './alarm-limit-audit-trail.component.html',
  styleUrls: ['./alarm-limit-audit-trail.component.scss']
})
export class AlarmLimitAuditTrailComponent {
  @Input() isVisible: boolean = false;
  @Input() selectedAlarmLimit: AlarmLimit;

  @Output() dialogHidden: EventEmitter<any> = new EventEmitter();

  isLoading: boolean = false;
  histories: AlarmLimitHistory[] = []; 
  parentId: string = '';

  constructor(private _opMonitoring: OperationalMonitoringApiService) {}

  ngOnInit(): void { }

  onDialogHide() {
    this.dialogHidden.emit();
  }

  getHistories(): void {
    this.isLoading = true;
    this.parentId = this.selectedAlarmLimit.id; 
    this._opMonitoring.getAlarmLimitHistories(this.parentId).pipe(take(1)).subscribe(({ data }) => {
      let returnedHistories = data.getAlarmLimitHistories; 
      returnedHistories.forEach((history: any) => {
        if (
          typeof history.timestamp === 'string' &&
          !history.timestamp.includes('.000+00:00')
        ) {
          history.timestamp += '.000+00:00';
        }

        history.timestamp = new Date(history.timestamp);
      });
      this.histories = returnedHistories;
      this.isLoading = false;
    })
  }

}

import { Injectable } from '@angular/core';
import { StationLog } from '../model/station-logs/station-log';
import { StationLogReason } from '../model/station-logs/station-log-reason';
import { FacilityBuilderService } from './facility-builder.service';

@Injectable({
  providedIn: 'root',
})
export class StationLogBuilderService {
  constructor(private _facilityBuilder: FacilityBuilderService) {}

  buildStationLogQuery(filters) {
    const query = {
      date: null,
      reason: null,
      message: null,
      facility: null,
      fixSolution: null,
      unitHours: null,
      createdAt: null,
      createdBy: null,
    };

    if (filters.date) {
      query.date = filters.date.value;
    }
    if (filters.reason) {
      query.reason = filters.reason.value.trim();
    }
    if (filters.facility) {
      query.reason = filters.facility.value.trim();
    }
    if (filters.message) {
      query.message = filters.message.value.trim();
    }
    if (filters.fixSolution) {
      query.fixSolution = filters.fixSolution.value.trim();
    }
    if (filters.unitHours) {
      query.unitHours = filters.unitHours.value.trim();
    }
    if (filters.createdAt) {
      query.createdAt = filters.createdAt.value;
    }
    if (filters.createdBy) {
      query.createdBy = filters.createdBy.value.trim();
    }

    return query;
  }

  buildStationLog(item: any): StationLog {
    return {
      id: item.id,
      date: item.date,
      reasonId: item.reasonId,
      message: item.message,
      fixSolution: item.fixSolution,
      unitHours: item.unitHours,
      createdAt: item.createdAt,
      createdBy: item.createdBy,
      updatedAt: item.updatedAt,
      updater: item.updater,
      deletedAt: item.deletedAt,
      reason: item.Reason ? this.buildStationLogReason(item.Reason) : null,
      facility: item.Facility ? this._facilityBuilder.buildFacility(item.Facility, []) : null
    };
  }

  buildStationLogReason(item: any): StationLogReason {
    return {
      id: item.id,
      reason: item.reason
    };
  }
}

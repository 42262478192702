import gql from 'graphql-tag';

export const editOpAlertLogAlarm = gql`
mutation editOpAlertLogAlarm($input: OpAlertLogAlarmInput) {
  editOpAlertLogAlarm(input: $input) {
    opAlertLogId
    time
    user
    alarm
    createdBy
    createdAt
    markAsDeleted
  }
}`;

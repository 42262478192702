import { Column } from '../../model/common/column';

export function getAlarmLimitsColumns(): Column[] {
    return [
        {
            field: "facilityName",
            header: "LOCATION.LABEL.FACILITY_NAME",
            mobileHeader: "LOCATION.LABEL.FACILITY_NAME",
            formatPattern: "text",
            type: "input",
            width: "30%",
            sortable: true,
            filterable: true,
            matchMode: "contains",
        },
        {
            field: "assetIdentifier",
            header: "OPMONITORING.LABEL.ASSET_IDENTIFIER",
            mobileHeader: "OPMONITORING.LABEL.ASSET_IDENTIFIER",
            formatPattern: "text",
            type: "input", 
            width: "10%", 
            sortable: true, 
            filterable: true, 
            matchMode: "equals"
        },
        {
            field: "alarmAttributeName",
            header: "OPMONITORING.LABEL.LIMIT_ATTRIBUTE_NAME",
            mobileHeader: "OPMONITORING.LABEL.LIMIT_ATTRIBUTE_NAME",
            formatPattern: "text", 
            type: "input", 
            width: "20%",
            sortable: true,
            filterable: true, 
            matchMode: "contains"
        },
        {
            field: "value",
            header: "OPMONITORING.LABEL.LIMIT_VALUE", 
            mobileHeader: "OPMONITORING.LABEL.LIMIT_VALUE", 
            formatPattern: "text",
            type: "input", 
            width: "10%",
            sortable: true, 
            filterable: true, 
            matchMode: "equals"
        },
        {
            field: "effectiveDateTime",
            header: "OPMONITORING.LABEL.EFFECTIVE_DATE_TIME", 
            mobileHeader: "OPMONITORING.LABEL.EFFECTIVE_DATE_TIME", 
            formatPattern: "calednar", 
            type: "calendar", 
            width: "10%",
            sortable: true, 
            filterable: true, 
            dateFormat: "yyyy-MM-dd hh:mm aaa" 
        }
    ];
}

import gql from 'graphql-tag';

export const getOpAlertLog = gql`
  query getOpAlertLog($id: ID!) {
    getOpAlertLog(id: $id) {
      id
      opLogStatus
      facilityId
      state
      county
      township
      businessUnitId
      createdAt
      createdBy
      updatedAt
      updatedBy
      opLogId
      piPoint
      BusinessUnit {
        id
        name
      }
      Facility {
        id
        ContactListId
        address
        gps
        facilityName
        facilityUid
        facilityType
        FacilityType {
          id
          name
        }
        scadaId
        Aor {
          id
          name
        }
        notes
        phone
        updater
        createdAt
        updatedAt
        deletedAt
        version
        area {
          id
          name
          region {
            id
            name
          }
        }
      }
      Contact {
        id
        firstName
        lastName
        nickName
        inactiveAdAccount
        afterHoursPhoneNumber
        speedDial
        version
        Company {
          id
          name
          abbreviation
          version
        }
        adAccountName
        JobRole {
          id
          name
          version
        }
        ReportsTo {
          id
          firstName
          lastName
          ReportsTo {
            id
            firstName
            lastName
            ReportsTo {
              id
              firstName
              lastName
            }
          }
          version
        }
        businessMobile
        businessPhone
        home
        other
        personalMobile
        adAccountName
        city
        Availabilities {
          id
          ContactId
          start
          end
          version
        }
      }
      OpAlertLogEntries {
        id
        opAlertLogId
        time
        user
        entry
        createdAt
        createdBy
        updatedAt
        deletedAt
        version
      }
      OpAlertLogAlarms {
        id
        opAlertLogId
        time
        user
        alarm
        createdAt
        createdBy
        updatedAt
        deletedAt
        version
      }
      OpAlertLogNotifications {
        id
        opAlertLogId
        time
        user
        note
      }
    }
  }
`;

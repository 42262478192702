import gql from 'graphql-tag';

export const queryForStationLogs = gql`
  query queryForStationLogs(
    $limit: Int
    $page: Int
    $query: StationLogSortOrQuery
    $sort: StationLogSortOrQuery
  ) {
    queryForStationLogs(
      limit: $limit
      page: $page
      query: $query
      sort: $sort
    ) {
        id
        date
        reasonId
        message
        fixSolution
        unitHours
        createdAt
        createdBy
        deletedAt
        updatedAt
        updater
        Reason {
          id
          reason
        }
        Facility {
          id
          facilityName
          facilityUid
          flocNumber
          Aor {
            id
            name
          }
        }
    }
  }
`;

import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  WritableSignal,
  signal,
} from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { DialogModule } from 'primeng/dialog';
import { CoreModule } from 'src/app/core/core.module';
import { PIValue } from 'src/app/model/operational-monitoring/pi-value';
import { OperationalMonitoringApiService } from 'src/app/services/operational-monitoring-api.service';

@Component({
  selector: 'app-pi-value-display',
  standalone: true,
  imports: [CoreModule, CommonModule, DialogModule, ChartModule],
  templateUrl: './pi-value-display.component.html',
  styleUrl: './pi-value-display.component.scss',
})
export class PiValueDisplayComponent implements OnInit {
  @Input() piPoint: string;

  loading: WritableSignal<boolean> = signal(true);
  plotLoading: WritableSignal<boolean> = signal(true);
  piValue: WritableSignal<PIValue> = signal(null);
  hours: WritableSignal<number> = signal(6);
  showChartDialog: boolean = false;
  piPlotData: PIValue[] = [];
  chartData = {};
  chartOptions = {
    parsing: {
      xAxisKey: 'timestamp',
      yAxisKey: 'value',
    },
  };

  constructor(private _opMonApi: OperationalMonitoringApiService) {}

  ngOnInit(): void {
    this.loadPIValue();
  }

  loadPIValue(): void {
    this.loading.set(true);
    this._opMonApi.getLatestPIValue(this.piPoint).subscribe(
      (piValue) => {
        this.piValue.set(piValue);
        this.loading.set(false);
      },
      (error) => {
        console.error(error);
        this.loading.set(false);
      }
    );
  }

  loadPIPlotData(): void {
    this.plotLoading.set(true);
    this.piPlotData = [];
    this._opMonApi.getPIValuePlot(this.piPoint, this.hours()).subscribe(
      (piPlotData) => {
        this.piPlotData = piPlotData;
        this.buildChartData();
        this.plotLoading.set(false);
      },
      (error) => {
        console.error(error);
        this.plotLoading.set(false);
      }
    );
  }

  openChartDialog(): void {
    this.loadPIPlotData();
    this.showChartDialog = true;
  }

  onChartHide(): void {
    this.showChartDialog = false;
  }

  buildChartData(): void {
    this.chartData = {
      datasets: [
        {
          label: this.piPoint,
          backgroundColor: '#0e406a',
          borderColor: '#0e406a',
          data: this.piPlotData.map((piValue) => {
            return {
              timestamp: piValue.timestamp.toLocaleTimeString(),
              value: piValue.value,
            };
          }),
        },
      ],
    };
  }
}

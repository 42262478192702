import gql from 'graphql-tag';

export const getPIValuePlot = gql`
  query getPIValuePlot($piPoint: String!, $hours: Int) {
    getPIValuePlot(piPoint: $piPoint, hours: $hours) {
      timestamp
      unitsAbbreviation
      value
      good
      questionable
      substituted
      annotated
    }
  }
`;

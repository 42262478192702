import gql from 'graphql-tag';

export const getPIConfigurableElements = gql`
query getPIConfigurableElements {
    getPIConfigurableElements {
        name
        webId
        configurableAttributes {
            name
            datatype
            children {
                name
                datatype
            }
        }
        facilityUid
        assetIdentifier
    }
}`;

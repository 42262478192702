import gql from 'graphql-tag';

export const editOpAlertLogEntry = gql`
mutation editOpAlertLogEntry($input: OpAlertLogEntryInput) {
  editOpAlertLogEntry(input: $input) {
    id
    opAlertLogId
    time
    user
    entry
    createdBy
    createdAt
    updatedAt
    version
  }
}`;

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { AlarmLimit } from 'src/app/model/operational-monitoring/alarm-limit';
import { AlarmLimitsTableService } from 'src/app/services/alarm-limits-table.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { getAlarmLimitsColumns } from '../../definitions/alarm-limits-table-definition';
import { Subscription, forkJoin, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { TabService } from 'src/app/core/services/tab.service';
import { OperationalMonitoringApiService } from 'src/app/services/operational-monitoring-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { isNullOrUndefined } from 'util';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AlarmLimitsDetailContainerComponent } from '../alarm-limits-detail-container/alarm-limits-detail-container.component';
import { AlarmLimitsBuilderService } from 'src/app/services/alarm-limits-builder.service';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';
import { RequestAlarmMonitoringComponent } from '../../components/request-alarm-monitoring/request-alarm-monitoring.component';
import { SYNC_ALARM_LIMITS_CONTAINER } from 'src/app/constants/common.constants';

@Component({
  selector: 'app-alarm-limits-search-container',
  templateUrl: './alarm-limits-search-container.component.html',
  styleUrls: ['./alarm-limits-search-container.component.scss'],
})
export class AlarmLimitsSearchContainerComponent
  extends SearchContainerComponent<AlarmLimit>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('alarmLimitForm')
  alarmLimitForm!: AlarmLimitsDetailContainerComponent;
  @ViewChild('buFilter') businessUnitFilter: BusinessUnitFilterComponent;
  @ViewChild('requestAM')
  alarmMonitoringRequest: RequestAlarmMonitoringComponent;

  displayDialog: boolean = false;
  displayRequestDialog: boolean = false;
  dialogHeader: string;
  refreshSubscription: Subscription;
  activeTabChangedSub: Subscription;
  formLoading: boolean = true;
  requestLoading: boolean = true;

  alarmLimits: AlarmLimit[] = [];
  formType: string = 'Create';
  showSuccessMessage = true;
  selectedAlarmLimit: AlarmLimit;

  canCreate = false;
  canSync = false;

  constructor(
    protected _deviceService: DeviceService,
    protected _loader: LoadingService,
    protected _auth: AuthService,
    private _authApi: AuthApiService,
    protected _cdRef: ChangeDetectorRef,
    protected _tableService: AlarmLimitsTableService,
    protected _logAndMessage: LogAndMessageService,
    private _translateService: TranslateService,
    private _breadCrumbBuilderService: BreadCrumbBuilderService,
    protected _opMonitoringApi: OperationalMonitoringApiService,
    private _fb: UntypedFormBuilder,
    private _alarmLimitBuilder: AlarmLimitsBuilderService
  ) {
    super(_deviceService, _loader, _auth, _cdRef);
  }

  ngOnInit() {
    this.canCreate = this._authApi.doesUserHaveAtLeastOneClaimFromList([
      'ChangeAllAlarmLimits',
      'ChangeDirectReportsAlarmLimits',
      'ChangeOwnAlarmLimits',
    ]);
    this.canSync = this._authApi.doesUserHaveAllClaimsFromList([
      'ChangeAllAlarmLimits',
    ]);
    this.setTableService(this._tableService);
    this.setupActiveTabChangedSubscription();
    this.columns = getAlarmLimitsColumns();

    const screenName$ = this._translateService.get(
      'OPMONITORING.SCREEN.ALARM_LIMITS'
    );
    const dialogHeader$ = this._translateService.get(
      'OPMONITORING.SCREEN.ALARM_LIMITS'
    );
    forkJoin([screenName$, dialogHeader$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
      this.dialogHeader = label[1];
    });

    const refreshInterval = interval(300000);
    this.refreshSubscription = refreshInterval.subscribe(() => {
      this.refresh(false);
    });
  }

  ngAfterViewInit(): void {
    this.applyPreviousData();
    this.applyPreviousFilters();
  }

  handleLazyLoad(req: any, $event: any, filters: any): void {
    let selectedBusinessUnits = [];

    if (this.businessUnitFilter?.selectedBusinessUnits) {
      selectedBusinessUnits = this.businessUnitFilter.selectedBusinessUnits;
    } else {
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }

    filters['businessUnits'] = selectedBusinessUnits;
    const query = this._alarmLimitBuilder.buildAlarmLimitQuery(filters);
    this.queryNetwork(req, $event, query);
  }

  queryNetwork(req, $event, query): void {
    req.page += 1;
    const sort = this.buildNetworkSortQuery(
      $event,
      'effectiveDateTime',
      'DESC'
    );

    this._opMonitoringApi
      .queryForAlarmLimits(req.pageSize, req.page, query, sort)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          const clone = Object.assign({}, data);
          this.totalRecords = clone.queryForAlarmLimits.totalRecords;
          this.alarmLimits = clone.queryForAlarmLimits.items;
          this.alarmLimits.forEach((al) => {
            al.facilityName = al.Facility.facilityName;
            (al.effectiveDateTime = new Date(al.effectiveDateTime)),
              (al.updatedAt = new Date(al.updatedAt));
          });
          this.elements = [...this.alarmLimits];
        },
        (error) => {
          this.loading = false;
          this._logAndMessage.errorLogOnly(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'OPMONITORING.MESSAGES.ERROR.QUERY_ALARM_LIMITS',
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
        },
        () => {
          this.loading = false;
          if (this.showSuccessMessage) {
            this.showSuccessMessage = false;
            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.QUERY_ALARM_LIMITS',
              headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
            });
          }
        }
      );
  }

  handleBusinessUnitChange($event) {
    this.lazyLoad({ lazy: this._tableService.getLastLazyLoad() });
  }

  changeViewEvent($event) {
    this.formType = 'Edit';
    this.selectedAlarmLimit = $event.data;

    setTimeout(() => {
      this.alarmLimitForm.updateFields();
      this.displayDialog = true;
    }, 5);
  }

  refresh(setLoading = true): void {
    this.lazyLoad({ lazy: this._tableService.getLastLazyLoad() }, setLoading);
  }

  setupActiveTabChangedSubscription(): void {
    this.activeTabChangedSub =
      TabService.getInstance().activeTabChanged.subscribe((tab) => {
        if (!isNullOrUndefined(tab) && tab.header === this.screenName) {
          this._tableService.setLastLazyLoad(
            this._tableService.getLastLazyLoad()
          );
          this.refresh();
        }
      });
  }

  clearScreen() {
    this.lazyLoad({ lazy: {} });
    this.grid.resetTable();
    this._tableService.clearResults();
    this.elements = [];
  }

  newAlarmLimit() {
    this.formType = 'Create';
    this.alarmLimitForm.resetEffectiveDate();
    this.alarmLimitForm.enableForm();
    this.displayDialog = true;
  }

  onFormHidden() {
    this.displayDialog = false;
  }

  onFormSubmitted() {
    this.refresh();
  }

  onAlarmDeleted() {
    this.refresh();
  }

  onPreparedForm() {
    this.formLoading = false;
  }

  requestAlarmMonitoring() {
    this.displayRequestDialog = true;
  }

  onRequestHidden() {
    this.displayRequestDialog = false;
  }

  onPreparedRequest() {
    this.requestLoading = false;
  }

  syncAlarmLimits() {
    const tab = TabService.getInstance().buildNewTab(
      SYNC_ALARM_LIMITS_CONTAINER,
      true
    );
    TabService.getInstance().openTab(tab);
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { BaseAppSyncService } from './base-app-sync.service';
import { AppsyncService } from './appsync.service';
import { Observable } from 'rxjs';
import { AuthApiService } from './auth-api.service';
import { CreateOrEditStationLog, StationLog } from '../model/station-logs/station-log';
import { StationLogReason } from '../model/station-logs/station-log-reason';
import { getStationLogReasons } from '../graphql/station-logs/get-station-log-reasons';
import { StationLogBuilderService } from './station-log-builder.service';
import { queryForStationLogs } from '../graphql/station-logs/query-for-station-logs';
import { getStationLog } from '../graphql/station-logs/get-station-log';
import { createStationLog } from '../graphql/station-logs/create-station-log';
import { editStationLog } from '../graphql/station-logs/edit-station-log';
import { deleteStationLog } from '../graphql/station-logs/delete-station-log';

@Injectable({
  providedIn: 'root',
})
export class StationLogApiService extends BaseAppSyncService {
  constructor(
    protected _api: AppsyncService,
    protected _authApi: AuthApiService,
    private _stationLogBuilder: StationLogBuilderService
  ) {
    super(_api);
  }

  getStationLogReasons(): Observable<StationLogReason[]> {
    return this.query(
      getStationLogReasons,
      {},
      (items: any[]) => items.map((i) => this._stationLogBuilder.buildStationLogReason(i)),
      'getStationLogReasons'
    );
  }

  queryForStationLogs(limit: number, page: number, query: any, sort: any): Observable<StationLog[]> {
    return this.query(
      queryForStationLogs,
      { limit, page, query, sort },
      (items: any[]) => items.map((i) => this._stationLogBuilder.buildStationLog(i)),
      'queryForStationLogs'
    );
  }

  getStationLog(logId: string): Observable<StationLog> {
    return this.query(
      getStationLog,
      { logId },
      (item: any) => this._stationLogBuilder.buildStationLog(item),
      'getStationLog'
    );
  }

  createStationLog(input: CreateOrEditStationLog): Observable<StationLog> {
    return this.mutate(
      createStationLog,
      { input },
      (i) => this._stationLogBuilder.buildStationLog(i),
      'createStationLog'
    );
  }

  editStationLog(input: CreateOrEditStationLog): Observable<StationLog> {
    return this.mutate(
      editStationLog,
      { input },
      (i) => this._stationLogBuilder.buildStationLog(i),
      'editStationLog'
    );
  }

  deleteStationLog(id: string): Observable<any> {
    return this.mutate(
      deleteStationLog,
      { id },
      (i) => i.value,
      'deleteStationLog'
    );
  }
}

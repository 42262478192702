import gql from 'graphql-tag';

export const getOpAlertLogs = gql`
query getOpAlertLogs($limit: Int, $page: Int, $query: OpAlertLogSortOrQuery, $sort: OpAlertLogSortOrQuery) {
  getOpAlertLogs(limit: $limit, page: $page, query: $query, sort: $sort) {
    items {
      id
      opLogId
      opLogStatus
      facilityId
      state
      county
      township
      businessUnitId
      buName
      createdAt
      createdBy
      updatedAt
      updatedBy
      acknowledgedBy
      areaId
      aor
      facilityName
      facilityUid
      facilityTypeName
      aorName
    }
    totalRecords
  }
}`;

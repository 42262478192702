import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-op-log-mobile-filter',
  templateUrl: './op-log-mobile-filter.component.html',
  styleUrls: ['./op-log-mobile-filter.component.scss']
})
export class OpLogMobileFilterComponent implements OnInit {

  @Output() mobileFilterEvent = new EventEmitter<any>();

  logId: string;
  facilityName: string;
  statusName: string;

  constructor() { }

  ngOnInit() {
  }

  mobileFilter() {
    this.mobileFilterEvent.emit({facilityUID: this.logId, facilityName: this.facilityName, statusName: this.statusName});
  }

  clear() {
    this.logId = null;
    this.facilityName = null;
    this.statusName = null;
    this.mobileFilter();
  }

}

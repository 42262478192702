import { Injectable } from '@angular/core';
import { AlarmLimit } from '../model/operational-monitoring/alarm-limit';
import { BaseTableService } from './base-table.service';

@Injectable({
  providedIn: 'root'
})
export class AlarmLimitsTableService extends BaseTableService<AlarmLimit> {
  private lastScreen: string;
  private addFilterOptions: any;

  constructor() {
    super(); 
   }

   setLastScreen(screen: string) {
    this.lastScreen = screen;
  }

  getLastScreen() {
    return this.lastScreen;
  }

  getAdditionalFilterOptions(): any {
    return this.addFilterOptions;
  }

  setAdditionalFilterOptions(options: any): void {
    this.addFilterOptions = options;
  }
}
